import cx from 'classnames'
import Link from 'next/link'
// import Marquee from 'react-fast-marquee'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

import { selectors, useAuth, useTranslate } from '~/hooks/actions'

import bannerStyles from './Banner.module.scss'
// import BannerStaticTicker from './StaticTicker'
// import CountdownTimer from './CountdownTimer'
import flags from '../../../assets/images/icons/flags.json'
import localeFlags from '../../../assets/images/icons/locales.json'
import { useExchange } from '~/context/exchange'
import { default as NextImage } from 'next/image'
// eslint-disable-next-line no-unused-vars
// import Select from 'react-select'

const Banner = ({ mobileMenu, setMobileMenu, locale }) => {
  const { isLoggedIn } = useAuth()
  const router = useRouter()
  const { getCartCount, getBookmarksCount, getNotificationsCount } = selectors
  const cartCount = useSelector(getCartCount)
  const { exchangeData, setSelectedCurrency, selectedCurrency } = useExchange()
  const [selectedLocale, setSelectedLocale] = useState(locale)
  const bookmarkCount = useSelector(getBookmarksCount)
  const notificationsCount = useSelector(getNotificationsCount)
  const translate = useTranslate()

  const setLocale = value => {
    setSelectedLocale(value)
    router.push(router.asPath, undefined, { locale: value })
  }

  // Bidding Kill Switch
  // const disableBiddingFlag = process.env.DISABLE_BIDDING_FLAG
  //   ? process.env.DISABLE_BIDDING_FLAG === 'true'
  //   : false
  // // Show hardcoded ticker instead of countdown
  // const staticTickerText = false
  // const tickerDate = '2023-12-11T08:00:00Z'
  // eslint-disable-next-line no-unused-vars
  const CurrencyOption = ({ innerProps, label }) => (
    <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      <NextImage
        src={flags[label.substring(0, 2)].image}
        alt={label.substring(0, 2)}
        width={20}
        height={20}
      />
      <span style={{ marginLeft: '8px' }}>{label}</span>
    </div>
  )

  // eslint-disable-next-line no-unused-vars
  const currencyValues =
    exchangeData?.data?.rates &&
    Object.keys(exchangeData.data.rates)
      .filter(currency => flags[currency.substring(0, 2)])
      .map(currency => ({
        value: currency,
        label: currency
      }))

  return (
    <section className={bannerStyles.banner}>
      {/* hamburger */}
      <div className={bannerStyles.menu}>
        <button
          className={
            mobileMenu ? bannerStyles.hamburgerActive : bannerStyles.hamburger
          }
          onClick={() => {
            setMobileMenu(!mobileMenu)
          }}>
          <span className={bannerStyles.hamburger__line}></span>
          <span className={bannerStyles.hamburger__line}></span>
          <span className={bannerStyles.hamburger__line}></span>
          <span className={bannerStyles.hamburger__line}></span>
        </button>

        <Link href="/account">
          <a className={bannerStyles.menuAccount}>
            <span
              className={cx('icon', 'icon--profile', bannerStyles.icon)}></span>
            {isLoggedIn && notificationsCount > 0 ? (
              <span className={bannerStyles.count}>[{notificationsCount}]</span>
            ) : null}
          </a>
        </Link>
      </div>
      {/* ticker */}
      {/*<Marquee*/}
      {/*  className={bannerStyles.marquee}*/}
      {/*  pauseOnHover={false}*/}
      {/*  gradient={false}*/}
      {/*  speed={40}>*/}
      {/*  {banner.map((item, index) => {*/}
      {/*    return disableBiddingFlag ? (*/}
      {/*      <BannerStaticTicker*/}
      {/*        key={index}*/}
      {/*        label={translate('header.banner.disable_bidding')}*/}
      {/*      />*/}
      {/*    ) : staticTickerText ? (*/}
      {/*      <BannerStaticTicker*/}
      {/*        key={index}*/}
      {/*        label={translate('header.banner.static_text')}*/}
      {/*      />*/}
      {/*    ) : (*/}
      {/*      <CountdownTimer*/}
      {/*        key={index}*/}
      {/*        label={translate('header.banner.ticker_text')}*/}
      {/*        commence={tickerDate}*/}
      {/*      />*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</Marquee>*/}
      {/* links */}
      <nav className={bannerStyles.nav}>
        <ul className={bannerStyles.list}>
          <li className={cx(bannerStyles.list_item, 'hide-large-down')}>
            <Link href="/archive">
              <a className={bannerStyles.bookmarks}>
                <span className={bannerStyles.label}>
                  {translate('header.banner.index')}
                </span>
              </a>
            </Link>
          </li>
          {/* Bookmarks */}
          {isLoggedIn && (
            <li className={cx(bannerStyles.list_item, 'hide-large-down')}>
              <Link href="/account">
                <a className={bannerStyles.bookmarks}>
                  <span className={bannerStyles.label}>
                    {translate('header.banner.bookmarks')}
                  </span>
                  <span className={bannerStyles.count}>[{bookmarkCount}]</span>
                </a>
              </Link>
            </li>
          )}
          {/* Account */}
          <li className={cx(bannerStyles.list_item, 'hide-large-down')}>
            <Link
              href={
                isLoggedIn
                  ? { pathname: '/account', query: { slug: 'activeBids' } }
                  : '/login'
              }>
              <a className={bannerStyles.accounts}>
                <span className={bannerStyles.label}>
                  {isLoggedIn
                    ? translate('header.banner.account')
                    : translate('account.register.login_link')}
                </span>
                {isLoggedIn && notificationsCount > 0 ? (
                  <span className={bannerStyles.count}>
                    [{notificationsCount}]
                  </span>
                ) : null}
              </a>
            </Link>
          </li>
          {/* Cart */}
          <li className={bannerStyles.list_item}>
            <Link href="/cart">
              <a
                className={cx(bannerStyles.cart, {
                  [bannerStyles.cartActive]: cartCount
                })}>
                <span
                  className={cx('icon', 'icon--bag', bannerStyles.icon)}></span>
                <div className={bannerStyles.text}>
                  <span className={bannerStyles.label}>
                    {translate('header.banner.cart')}
                  </span>
                  {cartCount > 0 && (
                    <span className={bannerStyles.count}>[{cartCount}]</span>
                  )}
                </div>
              </a>
            </Link>
          </li>
          {exchangeData?.data?.rates && (
            <li>
              {/*              <Select
                options={currencyValues}
                defaultValue={{ value: 'USD', label: 'USD' }}
                components={{ Option: CurrencyOption }}
                value={selectedCurrency}
                onChange={option => setCurrency(option.value)}
              />*/}
              <select
                value={selectedCurrency}
                onChange={e => setSelectedCurrency(e.target.value)}>
                {Object.keys(exchangeData.data.rates).map(currency => {
                  if (flags[currency.substring(0, 2)])
                    return (
                      <option
                        className={bannerStyles.currencyOption}
                        key={currency}
                        value={currency}>
                        {flags[currency.substring(0, 2)].emoji} {currency}
                      </option>
                    )
                })}
              </select>
            </li>
          )}
          <li>
            <select
              value={selectedLocale}
              onChange={e => setLocale(e.target.value)}>
              {router.locales.map(routerLocale => {
                const localeFlag = localeFlags.find(localeFlagObject => {
                  let flagLocale
                  switch (routerLocale) {
                    case 'en':
                      flagLocale = 'us'
                      break
                    case 'ja':
                      flagLocale = 'jp'
                      break
                    case 'zh':
                      flagLocale = 'cn'
                      break
                    case 'ko-KR':
                      flagLocale = 'kr'
                      break;
                    default:
                      flagLocale = 'us'
                      break
                  }
                  return (
                    localeFlagObject.code.toLowerCase() ===
                    flagLocale.toLowerCase()
                  )
                })
                if (localeFlag)
                  return (
                    <option
                      className={bannerStyles.currencyOption}
                      key={routerLocale}
                      value={routerLocale}>
                      {localeFlag.emoji} {routerLocale.split('-')[0].toUpperCase()}
                    </option>
                  )
              })}
            </select>
          </li>
        </ul>
      </nav>
    </section>
  )
}

export default Banner
